import styled from "styled-components"

export const Wrapper = styled.section`
  width: 100vw;
  @media (min-width: 660px) {
    /* height: 460px; */
  }
  display: flex;
  justify-content: center;
`

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;

  width: 100%;
  max-width: 1280px;

  padding: 120px 40px 120px 40px;

  @media (max-width: 550px) {
    padding: 48px 20px 64px 20px;
  }
`

export const Content = styled.div`
  position: relative;
  width: 100%;
  max-width: 880px;

  display: flex;
  flex-direction: column;
`
