import React from "react"

import * as S from "./style"
import { Title, Text } from "../UI/Type"
import useBaffle from '../../utils/useBaffle'

import twitterImage from "../../images/icons8-twitter.svg"
import linkedinImage from "../../images/icons8-linkedin.svg"

import { FormattedMessage } from 'react-intl'

const About = () => {
  const displayRef = React.useRef()
  const luisCatarinoRef = React.useRef()
  const davidRef = React.useRef()

  useBaffle([displayRef,luisCatarinoRef,davidRef])

  return (
    <S.Wrapper>
      <S.Container>
        <S.Content>
          <S.Column>
            <Title ref={displayRef}>
              <FormattedMessage id="companyPage.whoWeAre.title" />
            </Title>
          </S.Column>
          <S.Column>
            <Text medium>
              <FormattedMessage id="companyPage.whoWeAre.text" />
            </Text>
            <S.Team>
              <S.Title ref={luisCatarinoRef}>
                <FormattedMessage id="companyPage.whoWeAre.team.catarino.name" />
              </S.Title>
              <S.Role medium>
                <FormattedMessage id="companyPage.whoWeAre.team.catarino.jobTitle" />
              </S.Role>
              <S.Text medium>
                <FormattedMessage id="companyPage.whoWeAre.team.catarino.cv" />
              </S.Text>
              <S.Text medium>
                <S.Link href={`https://www.linkedin.com/in/-luis-catarino/`} target={'_blank'}>
                  <img src={linkedinImage}/>
                </S.Link>
                <S.Link href={`https://twitter.com/_lcatarino`} target={'_blank'}>
                  <img src={twitterImage}/>
                </S.Link>
              </S.Text>
            </S.Team>
            <S.Team>
              <S.Title ref={davidRef}>
                <FormattedMessage id="companyPage.whoWeAre.team.david.name" />
              </S.Title>
              <S.Role medium>
                <FormattedMessage id="companyPage.whoWeAre.team.david.jobTitle" />
              </S.Role>
              <S.Text medium>
                <FormattedMessage id="companyPage.whoWeAre.team.david.cv" />
              </S.Text>
              <S.Text medium>
                <S.Link href={`https://twitter.com/speeddragon`} target={'_blank'}>
                  <img src={twitterImage}/>
                </S.Link>
                <S.Link href={`https://www.linkedin.com/in/davidrmagalhaes/`} target={'_blank'}>
                  <img src={linkedinImage}/>
                </S.Link>
              </S.Text>
            </S.Team>
          </S.Column>
        </S.Content>
      </S.Container>
    </S.Wrapper>
  )
}

export default About
