import React from "react"

import * as S from "./style"
import { Big } from "../UI"

import { FormattedMessage } from 'react-intl'

const CompanyHero = () => {
  return (
    <S.Wrapper>
      <S.Container>
        <S.Content>
          <Big>
            <FormattedMessage id="companyPage.hero" />
          </Big>
        </S.Content>
      </S.Container>
    </S.Wrapper>
  )
}

export default CompanyHero
