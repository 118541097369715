import React from "react"
import Layout from "../components/Layout/Layout"
import CompanyHero from "../components/CompanyHero/CompanyHero"
import About from "../components/About/About"
import Network from "../components/Network/Network"

const CompanyPage = ({ pageContext: { locale } }) => {
  const metadata = {
    title: "companyPage.metadata.title",
    description: "companyPage.metadata.description",
  }

  return (
    <Layout locale={locale} metadata={metadata}>
      <CompanyHero />
      <About />
      <Network />
    </Layout>
  )
}

export default CompanyPage
