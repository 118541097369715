import styled from "styled-components"

import { Title as BaseTitle } from "../UI/Type"

import rightArrow from "../../images/right-arrow.svg"

export const Wrapper = styled.section`
  width: 100vw;

  display: flex;
  justify-content: center;
`

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  width: 100%;
  max-width: 1280px;

  padding: 80px 40px 80px 40px;

  @media (max-width: 550px) {
    padding: 48px 20px 48px 20px;
    padding-bottom: 0px;
  }
`

export const Content = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 880px;

  display: flex;

  margin-bottom: 100px;

  @media (max-width: 550px) {
    flex-direction: column;
    margin-bottom: 0px;
  }
`

export const Column = styled.div`
  width: 100%;

  &:first-child {
    width: 240px;
  }

  @media (max-width: 550px) {
    padding-bottom: 26px;
  }
`

export const Network = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 96px;

  @media (max-width: 550px) {
    flex-direction: column;
  }
`

export const Company = styled.div`
  width: calc(50% - 16px);

  @media (max-width: 550px) {
    width: 100%;
    padding-bottom: 60px;
  }
`

export const Title = styled(BaseTitle)`
  position: relative;
  font-weight: 500;
  padding-right: 10px;
  margin-bottom: 16px;

  &:after {
    content: "";
    position: absolute;
    display: block;

    top: -20px;
    left: 0px;

    height: 2px;
    width: 48px;

    background-color: #000;
  }
`

const getArrow = ({ white }) => {
  return white ? rightArrowWhite : rightArrow
}

export const Link = styled.a`
  position: relative;
  display: inline-block;

  padding-right: 24px;
  margin-top: 24px;

  &:after {
    content: "";
    display: block;
    position: absolute;

    top: 50%;
    transform: translateY(-50%);
    right: 0px;

    height: 12px;
    width: 12px;

    transition: right 0.5s cubic-bezier(0.2, 1, 0.2, 1);

    background-image: url(${getArrow});

    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 100%;
  }

  &:hover:after {
    right: -8px;
  }
`
