import React from "react"

import * as S from "./style"
import { Title, Text } from "../UI/Type"
import useBaffle from '../../utils/useBaffle'

import { FormattedMessage } from 'react-intl'

const About = () => {
  const displayRef = React.useRef()


  useBaffle([displayRef])

  return (
    <S.Wrapper>
      <S.Container>
        <S.Content>
          <S.Column>
            <Title ref={displayRef}>
              <FormattedMessage id="companyPage.network.mesh" />
            </Title>
          </S.Column>
          <S.Column>
            <Text medium>
              <FormattedMessage id="companyPage.network.text" />
            </Text>
            <S.Network>
              <S.Company>
                <S.Title>
                  <FormattedMessage id="companyPage.network.companies.significa.name" />
                </S.Title>
                <Text medium>
                  <FormattedMessage id="companyPage.network.companies.significa.description" />
                </Text>
                <S.Link href={'https://significa.pt'} target={'_blank'}>
                  <Text medium>
                    <FormattedMessage id="companyPage.network.companies.significa.findOutMore" />
                  </Text>
                </S.Link>
              </S.Company>
              <S.Company>
                <S.Title>
                  <FormattedMessage id="companyPage.network.companies.coletiv.name" />
                </S.Title>
                <Text medium>
                <FormattedMessage id="companyPage.network.companies.coletiv.description" />
                </Text>
                <S.Link href={'https://coletiv.com'} target={'_blank'}>
                  <Text medium>
                    <FormattedMessage id="companyPage.network.companies.coletiv.findOutMore" />
                  </Text>
                </S.Link>
              </S.Company>
            </S.Network>
          </S.Column>
        </S.Content>
      </S.Container>
    </S.Wrapper>
  )
}

export default About
